import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { useFormik } from 'formik';
import { default as cookies } from 'js-cookie';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loaderImg from '../../../assets/Loaders.json';
import Component from 'constants/Component';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';



const EditAccount = () => {
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    let { fetchPersonalData, fetchPersonal } = useContext(SharedData)
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            image: selectedImage
        },
        onSubmit: async (values) => {
            try {
                const url = `https://holmz.rightclick.com.sa/api/auth/update-personal-data`;
                let { data } = await axios.post(url, values, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': cookies.get('i18next'),
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                })
                console.log(data?.message);
                fetchPersonal()
                toast.current.show({ severity: 'success', summary: '', detail: data?.message });

                setTimeout(() => {
                    navigate('/account/personal_data')
                }, 1500);

            } catch (error) {

            }
        }
    });
    useEffect(() => {
        formik.setValues({
            name: fetchPersonalData?.name || '',
            email: fetchPersonalData.email || '',
            phone: fetchPersonalData.phone || '',
            image: fetchPersonalData.image || '',
        });
    }, [fetchPersonalData, formik.setValues]);

    const handleImageSelect = async (event) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('image', event.target.files[0]);

            console.log(event.target.files[0]);
            setSelectedImage(event.target.files[0]);
            formik.setFieldValue('image', event.target.files[0]);

            setInterval(() => {
                setIsLoading(false);
            }, 1500);
        } catch (error) {
            setIsLoading(false);
            console.error('Error uploading the image:', error);
        }
    };
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div className="app__PersonalData mb-8">
            <Toast ref={toast} position={'bottom-center'} />
            <form onSubmit={formik.handleSubmit} className='w-100'>
                <div className="header ">
                    <div className="img  ">
                        <div className="personal">
                            {
                                isLoading ?
                                    <>
                                        <div className="load_img">
                                            <Player
                                                className='w-100'
                                                // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                                src={loaderImg}
                                                autoplay
                                                loop
                                            />
                                        </div>
                                    </> :
                                    <>
                                        {selectedImage ?
                                            <img src={URL.createObjectURL(selectedImage)} className='w-100' alt="images" /> :
                                            <>
                                                {
                                                    formik.values.image ?
                                                        <img src={`${formik.values.image}`} className='w-100' alt="images" loading='lazy' /> :
                                                        <img src={img.personal} className='w-100' alt="images" loading='lazy' />
                                                }
                                            </>
                                        }
                                    </>
                            }


                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input"
                                    accept="image/*"
                                    onChange={handleImageSelect}
                                    style={{ display: 'none' }}
                                />
                                <div className="ChangeImage absolute">
                                    <label htmlFor="file-input" className="btn__porfile flex justify-content-center align-items-center" style={{ pointerEvents: 'all' }}>
                                        <Icons.Camera />
                                        {/* <i className='pi pi-image text-light color-wight'></i> */}
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="Edite">
                        <Link to={'/account/personal_data'}>
                            <Button icon='pi pi-cog' label={isLang === "en" ? 'Personal page' : '  صفحه الشخصيه '} />
                        </Link>
                    </div>
                </div>
                <div className="info_edit mt-50">

                    <div className="grid">
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="name" className="font-bold block mb-2">    {isLang === "en" ? ' First Name' : '  الاسم الأول  '} </label>
                            <InputText
                                id="name"
                                name="name"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}
                                value={formik.values?.name}
                            />
                            {formik.errors?.name && formik.touched?.name ?
                                <Message severity="error" text={formik.errors?.name} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="phone" className="font-bold block mb-2">{isLang === "en" ? ' phone number' : '  رقم الجوال '}   </label>
                            <InputText
                                id="phone"
                                name="phone"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir='ltr'
                                value={formik.values.phone}
                            />
                            {formik.errors.phone && formik.touched.phone ?
                                <Message severity="error" text={formik.errors.phone} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="email" className="font-bold block mb-2"> {isLang === "en" ? ' Email' : '  البريد الالكترونى   '}    </label>
                            <InputText
                                id="email"
                                name="email"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}

                                value={formik.values.email}
                            />
                            {formik.errors.email && formik.touched.email ?
                                <Message severity="error" text={formik.errors.email} /> : null
                            }
                        </div>



                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <div className="flex flex-column gap-2 mt-5 submit">
                                <Button severity='warning' label={isLang === "en" ? 'Save changes' : 'حفظ التغيرات '} size='small' className=' w-full' />
                            </div>
                        </div>
                    </div>


                </div>
            </form>
            <Component.ChangePasword
                isLang={isLang}
                toast={toast}
            />
        </div>
    )
}

export default EditAccount
