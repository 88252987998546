import { HeartOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import Icons from 'constants/Icons';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavNumber = ({ favourite, cart, scrollPosition }) => {
    const favouriteCount = favourite.length > 0 ? favourite.length : 0;

    return (
        <Nav.Item className='  flex flex-row justify-content-center align-items-center gap-4 w-100'>
            {
                localStorage.getItem('token') &&
                <Nav.Link as={Link} to='/wishlist' className='text-light wishlist' >
                    <Badge count={favouriteCount} style={{ backgroundColor: '#f5222d', border: 'none', padding: '0 4px' }}>
                        <HeartOutlined style={{ fontSize: '32px', color: '#fff' }} />
                    </Badge>
                </Nav.Link>
            }

            <Nav.Link as={Link} to='/cart' className='text-light' >
                <div className={`${scrollPosition > 600 ? 'app_card' : 'app_card_Scroll'}`} >
                    <Icons.Card />
                    <span>{cart?.length}</span>
                </div>
            </Nav.Link>
        </Nav.Item>)
}

export default NavNumber