import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Navber from 'components/Sidebar/Navber/Navber';
import Component from 'constants/Component';
import { LocalizationContext } from 'context/LangChange';

const Account = () => {
    let { isLang, isOpen } = useContext(LocalizationContext);

    return (
        <>
            <div className="vender " dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
                <div className='flex'>
                  <Component.Sidebar />
                   <Component.SidebarSM /> 
                    <motion.div

                        className='adminLayout'
                        animate={{
                            width: isOpen ? `calc( 100% - 250px )` : `calc( 100% - 50px )`,
                            transition: {
                                duration: 0.5,
                                type: "spring",
                                damping: 10,
                            },
                        }}
                    >
                        <main className='w-full   h-screen   m-0 p-0 '>
                            <Navber />
                            <div className='p-2 '  >
                                <Outlet></Outlet>
                            </div>
                            {/* <Component.Footer /> */}
                        </main>
                    </motion.div>
                </div>
            </div>
        </>
    )
}

export default Account
