import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { forgetPassword, loginUser } from './LoginLogic';
import './login.scss';

const Login = () => {
  let navigate = useNavigate();
  let { fetchCart } = useContext(SharedData);
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation();
  
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');

  const [loading, setLoading] = useState(false);
  const [loadingForgetPassword, setLoadingForgetPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      loginUser(values, setLoading, fetchCart, navigate, t, checked, message);
    }
  });

  const formikForgetPassword = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: (values) => {
      forgetPassword(values, setLoadingForgetPassword, navigate, t, setVisible, message);
    }
  });

  return (
    <>
      <Row className="vh-100" dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
        <Col xl={10} lg={10} md={24} sm={24} className={`${isLang === "ar" ? 'login_overlayAr' : 'login_overlay'} login_overlay_sm position-relative m-0 p-0`}>
          <img src={img.AuthBg1} alt="" className='w-100 h-100 object-fit-cover' />
          <div className="overlay">
            <img src={img.AuthBg2} alt="" className='w-100 object-fit-cover' />
          </div>
        </Col>
        <Col xl={14} lg={14} md={24} sm={24} className="app__auth h-100 flex flex-column justify-content-center align-items-center">
          <div className="app__login flex justify-content-center align-items-center">
            <div className="app__login-left">
              <img loading="lazy" src={img.logoDark} className='object-fit-contain' alt="Login page background" />
              <div className="login__form_inputs">
                <Form
                  layout="vertical"
                  onFinish={formik.handleSubmit}
                  className="mt-3  "
                >
                  <Form.Item
                    label={t('email_label')}
                    className={` mt-5`}
                    name="email"
                    rules={[{ required: true, message: t('email_label_error') }]}
                  >
                    <Input
                      id="email"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder={t('email_placeholder')}
                      size='large'
                      name="email"

                      className={`custom-input`}
                      dir='ltr'
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('password_label')}
                    name="password"
                    className={` mt-5`}

                    rules={[{ required: true, message: t('password_label_error') }]}
                  >
                    <Input.Password
                      id="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      placeholder={t('password_placeholder')}
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      type={handelOpenPassword}
                      size='large'
                    />
                  </Form.Item>

                  <div className="forget_password">
                    <div className="flex gap-2 align-items-center mb-2">
                      <Checkbox onChange={e => setChecked(e.target.checked)} checked={checked}>
                        {isLang === "en" ? 'Remember me' : 'تذكرنى'}
                      </Checkbox>
                    </div>
                    <span className='cursor-pointer' onClick={() => setVisible(true)}>
                      {isLang === "en" ? 'Forgot your password?' : 'هل نسيت كلمة السر؟'}
                    </span>
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className="w-100 mt-3"
                      size='large'
                    >
                      {t('Login_btn')}
                    </Button>
                  </Form.Item>
                </Form>

                <div className="app__now_account">
                  <span>{isLang === "en" ? `Don't have an account? ` : 'ليس لديك حساب؟'} <Link to={'/holmes/sign-up'}>{isLang === "en" ? 'New account' : 'حساب جديد'}</Link></span>
                </div>
              </div>
            </div>
            <div>
              <Link to={'/'} dir='ltr' className="btn_back cursor-pointer flex justify-content-center align-items-center gap-2">
                <Icons.ArrowBtn />
                <h6 className='m-0 p-0 text-dark'>{t('Back')}</h6>
              </Link>
            </div>
          </div>
        </Col>
        <Modal
          title="Send verification code"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
          centered
        >
          <div className="flex justify-content-center">
            <form onSubmit={formikForgetPassword.handleSubmit} className="flex flex-column gap-2 mt-5 mb-5 w-75">
              <span className="p-float-label">
                <Input
                  id="email"
                  name="email"
                  value={formikForgetPassword.values.email}
                  onChange={formikForgetPassword.handleChange}
                  className={`w-100`}
                // size='large'
                />
                <label htmlFor="input_value">{t('email_label')}</label>
              </span>
              <Button type="primary" htmlType="submit" className='w-100 mt-3' loading={loadingForgetPassword}>
                {t('Submit')}
              </Button>
            </form>
          </div>
        </Modal>
      </Row>
    </>
  );
};

export default Login;
