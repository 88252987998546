import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from './resetPasswordLogic';

const ResetPassword = () => {
    const [loademail, setLoadEmail] = useState(false);
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');

    let navigate = useNavigate();
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: '',
            code: '',
            new_password: '',
            password_confirmation: '',
        },
        onSubmit: (values) => {
            resetPassword(values, setLoadEmail, navigate, t);
        }
    });

    const handleChange = (newValue) => {
        formik.setFieldValue('code', newValue);
    };

    return (
        <>

            <Row className="vh-100" dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
                <Col xl={10} lg={10} md={24} sm={24} className={`${isLang === "ar" ? 'login_overlayAr' : 'login_overlay'} login_overlay_sm position-relative m-0 p-0`}>
                    <img src={img.AuthBg1} alt="" className='w-100 h-100 object-fit-cover' />
                    <div className="overlay">
                        <img src={img.AuthBg2} alt="" className='w-100 object-fit-cover' />
                    </div>
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} className="app__auth h-100 flex flex-column justify-content-center align-items-center">
                    <div className="app__login flex justify-content-center align-items-center">
                        <div className="app__login-left">
                            <img loading="lazy" src={img.logoDark} className='object-fit-contain' alt="Login page background" />
                            <h2 className='bolder mt-4'>Reset Password</h2>
                            <div className="login__form_inputs">
                                <Form layout="vertical" onFinish={formik.handleSubmit} >
                                    <Form.Item
                                        label={t('email_label')}
                                        name="email"
                                        rules={[{ required: true, message: t('email_label_error') }]}
                                    >
                                        <Input
                                            id="email"
                                            type="email"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            placeholder={t('email_placeholder')}
                                            size='large'
                                        />
                                    </Form.Item>



                                    <Form.Item
                                        label={t('password_label')}
                                        name="new_password"
                                        rules={[{ required: true, message: t('password_label_error') }]}
                                    >
                                        <Input.Password
                                            id="new_password"
                                            name="new_password"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            placeholder={t('password_placeholder')}
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            type={handelOpenPassword}
                                            size='large'

                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('password_confirmation')}
                                        name="password_confirmation"
                                        rules={[{ required: true, message: t('password_confirmation_label') }]}
                                    >
                                        <Input.Password
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            placeholder={t('password_placeholder')}
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            type={handelOpenPassword}
                                            size='large'

                                        />
                                    </Form.Item>
                                    <Form.Item label={t('otp_label')} name="otp" rules={[{ required: true, message: t('otp_label_error') }]}>
                                        <div className=" "> 
                                            <Input.OTP length={6} onChange={handleChange} size='large' />
                                        </div>
                                    </Form.Item>
                                    <div className="w-100 app__login-btn d-flex align-items-center justify-content-center">
                                        <Button loading={loademail} type='primary' htmlType="submit" size='large' className='w-100 mt-3'>
                                            {t('Login_btn')}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div>
                            <Link to={'/holmes/sign-in'} dir='ltr' className="btn_back cursor-pointer flex justify-content-center align-items-center gap-2">
                                <Icons.ArrowBtn />
                                <h6 className='m-0 p-0 text-dark'>{t('Back')}</h6>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ResetPassword;
