import axios from 'axios'
import { useFormik } from 'formik'
import { default as cookies } from 'js-cookie'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { useState } from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useValidation from 'validation/useValidation'

const ChangePasword = ({ isLang, toast }) => {
    let { validateChangePassword } = useValidation()
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    const [handelOpenPassword1, setHandelOpenPassword1] = useState('password');
    const [handelOpenPassword2, setHandelOpenPassword2] = useState('password');
    let navigate = useNavigate() 

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            password_confirmation: ''
        },
        validationSchema: validateChangePassword,

        onSubmit: async (values) =>  {
            try {
                const url = `https://holmz.rightclick.com.sa/api/auth/update-password`;
                let { data } = await axios.post(url, values, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': cookies.get('i18next'),
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }) 
                console.log(data);
            if (data?.status===200) {
                    toast.current.show({ severity: 'success', summary: '', detail: data?.message });
                    setTimeout(() => {
                        navigate('/account/personal_data')
                    }, 1500);
                }else{
                    toast.current.show({ severity: 'error', summary: '', detail: data?.message });
                } 


            } catch (error) {

            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className='mt-4 w-100'>
            <Row  >
                <div className="change_password mt-60">
                 {/*    <Component.Title title={'تغير كلمة المرور'} /> */}
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="old_password" className="  block mb-2">   {isLang === "en" ? '   Old password  ' : '    كلمة المرور القديمة   '} </label>
                        <div className="password_open">
                            <InputText
                                id="old_password"
                                name="old_password"
                                type={handelOpenPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='w-full bg-light p-inputtext-sm' />
                            {
                                handelOpenPassword === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>

                            }
                        </div>
                        {formik.errors.old_password && formik.touched.old_password ?
                            <Message severity="error" text={formik.errors.old_password} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="new_password" className="font-bold_password block mb-2">   {isLang === "en" ? '   New Password' : ' كلمة المرور الجديدة '} </label>
                        <div className="password_open">
                            <InputText
                                id="new_password"
                                name="new_password"
                                type={handelOpenPassword1} onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='bg-light p-inputtext-sm w-full' />
                            {
                                handelOpenPassword1 === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword1('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword1('password')}  ></i>

                            }
                        </div>

                        {formik.errors.new_password && formik.touched.new_password ?
                            <Message severity="error" text={formik.errors.new_password} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="password_confirmation" className="font-bold_password block mb-2">   {isLang === "en" ? 'confirm password' : 'تأكيد كلمة المرور   '} </label>
                        <div className="password_open">
                            <InputText
                                id="password_confirmation"
                                name="password_confirmation"
                                type={handelOpenPassword2}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='bg-light p-inputtext-sm w-full' />
                            {
                                handelOpenPassword2 === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword2('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword2('password')}  ></i>

                            }
                        </div>

                        {formik.errors.password_confirmation && formik.touched.password_confirmation ?
                            <Message severity="error" text={formik.errors.password_confirmation} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                    <div className="flex flex-column gap-2 mt-60 submit">
                        <Button type='submit' severity='warning' label={isLang === "ar" ? 'تغير كلمه السر' : 'Change Password  '} className=' w-full' size='small' />
                    </div>
                </div>
            </Row>
        </form>


    )
}

export default ChangePasword