import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRegisterLogic } from './RegisterLogic';


const Register = () => {
  let { isLang } = useContext(LocalizationContext)
  const { t } = useTranslation();
  const {
    formik,
    loademail,
    handelOpenPassword,   
  } = useRegisterLogic();
  return (
    <>
      <Row className="vh-100" dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
        <Col xl={5} lg={5} md={12} sm={12} className={`${isLang === "ar" ? 'login_overlayAr' : 'login_overlay'} login_overlay_sm   position-relative m-0 p-0`}   >
          <img src={img.AuthBg1} alt="" className='w-100 h-100 object-fit-cover' />
          <div className="overlay">
            <img src={img.AuthBg2} alt="" className='w-100 object-fit-cover' />
          </div>
        </Col>
        <Col xl={7} lg={7} md={12} sm={12} className="app__auth h-100  flex flex-column justify-content-center align-items-center  ">
          <div className="app__login    flex justify-content-center align-items-center">
            <div className="app__login-left">
              <img loading="lazy" src={img.logoDark} className='object-fit-contain' alt="Login page background" />
              <div className="login__form_inputs">
                <Form
                  layout="vertical"
                  onFinish={formik.handleSubmit}
                  className="mt-3"
                >
                  <Form.Item
                    label={t('name_label')}
                    name="name"
                    rules={[{ required: true, message: t('username_label_error') }]} 
                    
                  >
                    <Input
                      id="name"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder={t('name_placeholder')}
                      size='large'
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('phone_label')}
                    name="phone"
                    rules={[{ required: true, message: t('phone_label_error') }]}
                  >
                    <Input
                      id="phone"
                      type="tel"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      placeholder={t('phone_placeholder')}
                      size='large'
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('email_label')}
                    name="email"
                    rules={[{ required: true, message: t('email_label_error') }]}
                  >
                    <Input
                      id="email"
                      type="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder={t('email_placeholder')}
                      size='large'
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('password_label')}
                    name="password"
                    rules={[{ required: true, message: t('password_label_error') }]}
                  >
                    <Input.Password
                      id="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      placeholder={t('password_placeholder')}
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      type={handelOpenPassword}
                      size='large'

                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loademail}
                /*       disabled={!(formik.isValid && formik.dirty) || loademail} */
                      className="w-100 mt-3"
                      size='large'
                    >
                      {t('Login_btn')}
                    </Button>
                  </Form.Item>
                </Form>
                <div className="app__now_account">
                  <span >  {isLang === "en" ? `Do you have an account? ` : '  ليس لديك حساب؟  '} <Link to={'/holmes/sign-in'}>   {isLang === "en" ? 'sign in' : ' حساب جديد  '}</Link></span>
                </div>
              </div>
            </div>
            <div >
              <Link to={'/'} dir='ltr' className="btn_back cursor-pointer flex justify-content-center align-items-center gap-2">
                <Icons.ArrowBtn />
                <h6 className='m-0 p-0 text-dark'>{t('Back')}</h6>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Register
