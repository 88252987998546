import axios from 'axios';
import { showNotification } from 'utils/notification';

export const resetPassword = async (values, setLoadEmail, navigate, t) => {
    try {
        setLoadEmail(true);
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/reset-password`, values);
        setLoadEmail(false);
        if (data?.status === 200) {
            showNotification('success', t('success'), data.message);
            navigate('/holmes/sign-in');
        } else {
            showNotification('error', t('failed'), data.message);
        }

    } catch (error) {
        setLoadEmail(false);
        if (error?.response?.status === 422) { 
            handleErrors(error?.response?.data?.errors, t);
        }
    }
};

const handleErrors = (errors, t) => {
    if (errors?.email) {
        showNotification('error', t('failed'), errors.email); 
    }
    if (errors?.code) {
         showNotification('error', t('failed'), errors.code);
    }
    if (errors?.new_password) {
        showNotification('error', t('failed'), errors.new_password); 
    }
    if (errors?.password_confirmation) { 
        showNotification('error', t('failed'), errors.password_confirmation); 
    }
};

