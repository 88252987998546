
import { HomeFilled, HomeOutlined, InfoCircleOutlined, PhoneOutlined, ProductOutlined } from '@ant-design/icons';
import Icons from 'constants/Icons';

const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameFr: "Home",
        nameAr: "  الرئيسية",
        icon: <HomeOutlined />,
    },
    {
        path: "/about",
        nameEn: "About us  ",
        nameFr: "About us  ",
        nameAr: "وسائط",
        icon: <InfoCircleOutlined />,
    },
    {
        path: "/products",
        nameEn: "Our product",
        nameFr: "Our product",
        nameAr: "منتجاتنا",
        icon: <ProductOutlined />,
    },
    {
        path: "/agent",
        nameEn: "New Agent",
        nameFr: "New Agent",
        nameAr: "اتصل بنا",
        icon: <PhoneOutlined />,
    },
    {
        path: "/contact-us",
        nameEn: "Contact Us ",
        nameFr: "Contact Us ",
        nameAr: "وكيل جديد",
        icon: <PhoneOutlined />,
    },



];

export default routes