import Cookies from 'js-cookie';
import { createContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(Cookies.get('i18next'));
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    Cookies.set('i18next', isLang)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [isLang])

  async function LogOut() {
    // let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/logout`, {}, headerApi)
    
    // if (data?.status === 200) {
    //   toast.success(data?.message)
    // }

    localStorage.removeItem("token");
    return <Navigate to="/" replace={true} />
  }
  return (
    <LocalizationContext.Provider value={{ isOpen, setIsOpen, LogOut, loading, setLoading, isLang, setIsLang }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange