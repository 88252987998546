import { Button, Col, Rate, Row } from 'antd';
import axios from 'axios';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { Card } from 'primereact/card';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './favorite.scss';
import { TagOutlined } from '@ant-design/icons';

const Favorite = () => {

  let { fetchHome, fetchCart, favourite } = useContext(SharedData)
  let { fetchFavourite, fetchProductsData } = useContext(SharedData)
  let { t } = useTranslation();

  const header = (item) => (
    <img alt="Card" className='p-2 shadow-1 product-image' src={item.image} />
  );

  const footer = (item) => (
    <div className='footer_btn flex justify-content-between align-items-center  '>
      <div className="card">
        <Link to={`/products/${item.id}`}>
          <Button type="primary" className="buy-button px-3" size="" icon={<TagOutlined />}>
            Buy
          </Button>
        </Link>
      </div>
    </div>
  );

  const renderTitle = (item) => (
    <div className='flex justify-content-between align-items-center  '>
      <h3 className='product_name p-0 m-0'>{item?.name}</h3>
      <span className='product_price'>{item?.price} <small>SAR</small></span>
    </div>
  );

  const deleteProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/${id}/delete`;
    let { data } = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),

      }
    })
    if (data?.status === 200) {
      fetchFavourite()
      fetchProductsData()
      fetchCart() 
    }
  }

  const deleteAllProduct = async () => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/destroy`;
    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),

      }
    })

    if (data?.status) {
      fetchFavourite()
      fetchProductsData()
      fetchCart()
      toast.success(data?.message)
    }
  }

  return (
    <div className='app__favorite'>
      <div className="app__slider bg-black">
        <img src={fetchHome.slider_video} className='w-100 ' alt="" srcset="" />
      </div>

      <div className="container_cart">
        <header className='app_cart_header flex justify-content-between align-items-center'>

          <div className="remove-all-favorite" onClick={deleteAllProduct}>
            <span>Remove All</span>
          </div>

          <div className="Contact-header flex gap-4 justify-content-center align-items-center">
            <Icons.Favorite />
            <span>{favourite?.length}</span>
          </div>

          <div className="icons  cursor-pointer">
            <Icons.Close />
          </div>

        </header> 
        
        <body className='app__favorite-body mt-7 bg-transparent'>
          <div className="app__about_banner app__about_explore flex justify-content-center flex-column">
            <Row gutter={[16, 16]}>
              {
                favourite?.map((item) => (
                  <Col xl={8} lg={8} md={12} sm={24} key={item.id} className='mt-3 w-100'>
                    <Card
                      title={renderTitle(item)}
                      subTitle={item?.shot_description}
                      footer={footer(item)}
                      header={header(item)}
                      className='w-100 position-relative'>
                      <Rate disabled value={item?.average_rate ? item?.average_rate : 0} />
                      <div className="icons_close shadow-lg" onClick={() => deleteProduct(item?.id)}>
                        <Icons.Close />
                      </div>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </div>
        </body>
      </div>
    </div>
  )
}

export default Favorite
