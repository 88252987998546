import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NavLinks = ({cookies,t,location}) => {
    return (
        <Nav className={`${cookies.get('i18next') === "en" ? 'dropdown_en' : 'dropdown_ar'}`}>
            <Nav.Link as={Link} to='/agent' className={`  ${location.pathname === '/agent' ? 'active' : 'text-light'}`}> {t('nav_agent')} </Nav.Link>
            <Nav.Link as={Link} to='/contact-us' className={`  ${location.pathname === '/contact-us' ? 'active' : 'text-light'}`}>{t('nav_contact')} </Nav.Link>
            <Nav.Link as={Link} to='/products' className={`  ${location.pathname === '/products' ? 'active' : 'text-light'}`}>{t('nav_product')} </Nav.Link>
            <Nav.Link as={Link} to='/about' className={`  ${location.pathname === '/about' ? 'active' : 'text-light'}`}>{t('nav_about')} </Nav.Link>
            <Nav.Link as={Link} to='/' className={` ${location.pathname === '/' ? 'active' : 'text-light'}`}>{t('nav_home')}</Nav.Link>
        </Nav>
    )
}

export default NavLinks