import axios from 'axios'
import cookies from 'js-cookie'
import { Button } from 'primereact/button'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LocalizationContext } from 'context/LangChange'
import './product.scss'

const Product = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [data, setData] = useState([]);
    const fetchData = async () => {
        const url = `https://holmz.rightclick.com.sa/api/home/products?page=-1`;
        try {
            let { data } = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': cookies.get('i18next')
                }
            })
            if (data?.status === 200) {
                setData(data?.data?.data); 
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()
    }, [isLang])
    return (
        <>
            <div className="app__product_home">
                <header className='flex justify-content-center align-items-center flex-column'>
                    <h3> {t('product_title')}  </h3>
                    <p>Lörem ipsum kombucha lävis dir divas. Mill ontoception. Leledes hångar. Janing nätdeklarant och valig. Euron sebelt inte bahåv bell sare. Fide anagram, att lamörede. </p>
                </header>
 

                {
                    data?.length >= 0 &&
                    <section className='overflow-hidden mt-4 app__product_home_section2 mb-8'>
                        <Row>
                            {
                                data?.map((item, index) => (
                                    <Col xl={6} lg={6} md={12} sm={12} className='   mt-4'>
                                        <img src={item?.image} alt="" className='w-100 cart_image' />
                                        <Row>
                                            <Col xl={5} lg={5} md={5} sm={12} className='card_style shadow' >
                                                <div className="card_info flex align-items-center justify-content-between w-100 ">
                                                    <div className="header">
                                                        <h2> {item?.name}  </h2>
                                                    </div>
                                                    <div className="footer">
                                                        <span>0{index + 1}</span>
                                                    </div>
                                                </div>
                                                <section className='card_details    flex flex-row justify-content-between align-items-center'>
                                                    <div className="card_header ">
                                                        <Link to={`/products/${item.id}`}>
                                                            <Button severity="warning" label="Buy" icon="pi pi-tag " iconPos="right" className='shadow mb-3 ' />
                                                        </Link>
                                                    </div>
                                                    <div className="card_body flex justify-content-between flex-row align-items-center">
                                                        <div className="more   ">
                                                            <Link>More</Link>
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={6} className='h-100 app__card_description    '>
                                                <div className="description">

                                                    <section className='product_title mt-3'>
                                                        <span>Description</span>
                                                    </section>
                                                    <section className='product_footer'>
                                                        <div className="card_body ">
                                                            <span>olfactive notes</span>
                                                            <p>{item?.shot_description} </p>
                                                        </div>

                                                    </section>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                        </Row>
                    </section>
                }


            </div>
        </>
    )
}

export default Product