import React from 'react'
import img from 'constants/Img'
import { Col, Row } from 'react-bootstrap'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'

const AboutHome = ({ data }) => {
    return (
        <>
            <div className="app__about_banner  overflow-hidden ">
                <div className="banner "   >
                    <img src={img.banner} className='w-100' alt="" srcset="" />
                    <div className="overlay">
                        <Row className='container-fluid flex align-items-center justify-content-between h-100'>
                            <Col xl={9} lg={9} md={8} sm={12} className='app_about_content'>
                                <h2 className='text-light'>{data?.title}</h2>
                                <p className='text-light'>{data?.description}</p>
                                <Link to='/products'>
                                    <div className="btn_about"> 
                                        <Button label='Shop Now' text />
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHome