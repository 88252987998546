import { Rate } from 'antd';
import axios from 'axios';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FavoritesPersonal = () => {

    let { fetchHome, fetchCart, favourite } = useContext(SharedData)
    let { fetchFavourite, fetchProductsData } = useContext(SharedData)
    let { t } = useTranslation();



    const header = (item) => (
        <img alt="Card" className='p-2 shadow-1' src={item.image} />
    );

    const footer = (item) => (
        <div className='footer_btn flex justify-content-between align-items-center  '>
            <div className="card">
                <Link to={`/products/${item.id}`}>
                    <Button severity="warning" label="Buy" icon="pi pi-tag " iconPos="right" />
                </Link>
            </div>
        </div>
    );

    const renderTitle = (item) => (
        <div className='flex justify-content-between align-items-center  '>
            <h3 className='product_name p-0 m-0'>{item?.name}</h3>
            <span className='product_price'>{item?.price} <small>SAR</small></span>
        </div>
    );

    const deleteProduct = async (id) => {
        const url = `https://holmz.rightclick.com.sa/api/favourite/${id}/delete`;
        let { data } = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),

            }
        })
        if (data?.status === 200) {
            fetchFavourite()
            fetchProductsData()
            fetchCart()

        }
    }


    const deleteAllProduct = async () => {
        const url = `https://holmz.rightclick.com.sa/api/favourite/destroy`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),

            }
        })

        if (data?.status) {
            fetchFavourite()
            fetchProductsData()
            fetchCart()
            toast.success(data?.message)
        }
    }

    return (
        <div className='card-personal'>

            <div className='app__favorite'>


                <div className="container_cart">
                    <header className='app_cart_header flex justify-content-between align-items-center'>

                        <div className="remove-all-favorite" onClick={deleteAllProduct}>
                            <span>Remove All</span>
                        </div>

                        <div className="Contact-header flex gap-4 justify-content-center align-items-center">
                            <Icons.Favorite />
                            <span>{favourite?.length}</span>
                        </div>

                        <div className="icons  cursor-pointer">
                            <Icons.Close />
                        </div>

                    </header>

                    <body className='app__favorite-body mt-7 bg-transparent'>
                        <div className="app__about_banner app__about_explore flex justify-content-center flex-column">
                            <Row>
                                {
                                    favourite?.map((item, index) => (
                                        <Col index={item} xl={4} lg={4} md={6} sm={12} className='mt-3'>
                                            <Card
                                                title={renderTitle(item)}
                                                subTitle={item?.shot_description}
                                                footer={footer(item)}
                                                header={header(item)}
                                                className='w-100 position-relative'>
                                                {
                                                    item?.average_rate &&
                                                    <Rate disabled value={item?.average_rate} />

                                                }
                                                <div className="icons_close shadow-lg" onClick={() => deleteProduct(item?.id)}>
                                                    <Icons.Close />
                                                </div>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                    </body>
                </div>
            </div>
        </div>


    )
}

export default FavoritesPersonal