import Icons from "constants/Icons";
import img from "constants/Img";
import { LocalizationContext } from "context/LangChange";
import { AnimatePresence, motion } from "framer-motion";
import i18next from "i18next";
import cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { useContext, useState } from 'react';
import { Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import './Sidebar.scss';
import SidebarMenu from "./SidebarMenu";
import routes from './route.js';

const Sidebar = ({ children }) => {
  const { t } = useTranslation()

  let { LogOut, isLang, setIsLang, isOpen, setIsOpen } = useContext(LocalizationContext);

  const handleLanguageChange = (selectedLanguageCode) => {
    setIsLang(selectedLanguageCode);
    if (selectedLanguageCode === 'fr') {
      document.body.dir = 'ltr';
    } else {
      document.body.dir = 'rtl';
    }
    i18next.changeLanguage(selectedLanguageCode);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: { duration: 0.4, when: "afterChildren" },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };
  let navigate = useNavigate()
  const handeLogOut = () => {
    LogOut()
    navigate('/')
  }

  const [modalDone, setModalDone] = useState(false);
  return (
    <>
      <div className="main-container " dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
        <motion.div
          dir={isLang === 'ar' ? 'rtl' : 'ltr'}
          animate={{
            width: isOpen ? "250px" : "50px",
            // background: '#fff',
            transition: {
              duration: 0.7,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar  `}
        >
          <div className="side   " style={{ [isLang === 'ar' ? 'right' : 'left']: 0 }}>
            <div className="top_section  ">
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo "
                    key={1}
                  >
                    <Link to={'/'} style={{ cursor: 'pointer' }} className="mt-5">
                      <img loading="lazy" src={img.Logo} alt='logo' width={50} />
                    </Link>
                  </motion.div>
                )}

              </AnimatePresence>
            </div>
            <section className={isLang === 'ar' ? 'routes routesAr' : 'routes'}   >
              {
                routes?.map((root, i) => {
                  if (root.subRoutes) {
                    return (
                      <SidebarMenu
                        key={i}
                        setIsOpen={setIsOpen}
                        route={root}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                        open={isOpen}
                        isLang={isLang}
                      />
                    );
                  }
                  return (
                    <motion.div
                      key={i}
                      animate={{
                        transition: {
                          duration: 2,
                          damping: 10
                        }
                      }}
                    >
                      <NavLink to={root.path} key={i} className="link " >
                        <div className="icon" id={root.name} data-tooltip-content={isLang === 'ar' ? root.nameAr : root.nameEn}>
                          {root.icon}
                        </div>
                        <AnimatePresence>
                          {
                            isOpen &&
                            <>
                              <motion.div
                                variants={showAnimation}
                                initial={"hidden"}
                                animate={"show"}
                                exit={"hidden"}
                                className="link_text"
                              >
                                {isLang === 'ar' ? root.nameAr : root.nameEn}
                              </motion.div>
                            </>
                          }
                        </AnimatePresence>
                      </NavLink>
                    </motion.div>
                  )
                })
              }

              <motion.div
                className="logout  flex flex-column justify-content-end "
                animate={{
                  transition: {
                    duration: 2,
                    damping: 10
                  }
                }}
              >
                <NavLink className="link " >
                  <div className="icon"   >
                    <Icons.Login style={{ width: 17 }} />
                  </div>
                  <AnimatePresence>
                    {
                      isOpen &&
                      <>
                        <motion.div
                          variants={showAnimation}
                          initial={"hidden"}
                          animate={"show"}
                          exit={"hidden"}
                          className="link_text logout"
                        >
                          <Link onClick={() => handeLogOut()}>
                            {isLang === 'ar' ? 'تسجيل الخروج' : 'LogOut'}
                          </Link>
                        </motion.div>
                      </>
                    }
                  </AnimatePresence>
                </NavLink>
                <div className="change_lang-sidebar " dir='rtl '> 
                  <div className='app__menu-talk cursor-pointer' onClick={() => setModalDone(true)}  >
                    {t("change_language")}
                    <i className="pi pi-language"></i>
                  </div>
                </div>
              </motion.div>
              <hr /> 
            </section>
          </div>
        </motion.div>
      </div>

      <Modal
        show={modalDone}
        onHide={() => setModalDone(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
      > 
        <Modal.Body>
          <Row className="change_lang_popup" dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <span className="text-center">اختر لغتك المفضلة </span>
            <div className="flex flex-column justify-content-center align-items-center gap-3 mt-5">
              <Button label="العربيه" icon='flag-icon flag-icon-sa' outlined={isLang === "ar" ? true : false} onClick={() => handleLanguageChange('ar')} />
              <Button label="English" icon='flag-icon flag-icon-gb' outlined={cookies.get('i18next') === "en" ? true : false} onClick={() => handleLanguageChange('en')} />
              <Button label="Français" icon='flag-icon flag-icon-fr' outlined={cookies.get('i18next') === "fr" ? true : false} onClick={() => handleLanguageChange('fr')} />
            </div>
          </Row>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Sidebar;