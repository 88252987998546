import { useContext } from 'react'
import Component from 'constants/Component'
import { SharedData } from 'context/CallApi'
import './home.scss'
const Home = () => {
  let { fetchMostProducts, fetchHome } = useContext(SharedData)
  // let { isLang } = useContext(LocalizationContext) 
  return (
    <>
      <div className="app__slider bg-black">
        <img src={fetchHome.slider_video} className='w-100 ' alt="" srcset="" />
      </div>
      <Component.AboutHome data={fetchHome?.product} />
      <Component.ExploreHome data={fetchMostProducts} />
      <Component.Product />
      <img src={fetchHome.footer_image} alt="" className='w-100' />
    </>
  )
}

export default Home