import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useRef, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import './Navber.scss';
import { useTranslation } from 'react-i18next';


function Navber() {
  let { LogOut, isOpen, setIsOpen, isLang } = useContext(LocalizationContext);
  const { t } = useTranslation()

  const location = useLocation();
  let { fetchPersonalData } = useContext(SharedData);

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const onAvatarClick = (event) => {
    menuRef.current.show(event);
    setShowMenu(!showMenu);
  };

  const start = (
    <>
      <div className="SunNavbar2">
        <div className='flex   flex-row gap-5 align-items-center nabar_side'>
          <Button rounded style={{ width: '35px', height: '35px' }} outlined text raised className='mr-2 ml-2' onClick={() => setIsOpen(!isOpen)} icon="pi pi-bars" size='small' />
          <div className="nabar_side-a">
            <Nav
              className="m-auto my-1 my-lg-0 flex gap-2 "
              style={{ maxHeight: '100px' }}
            >
              <Nav.Link as={Link} to='/agent' className={`  ${location.pathname === '/agent' ? 'active' : 'text-light'}`}> {t('nav_agent')} </Nav.Link>
              <Nav.Link as={Link} to='/contact-us' className={`  ${location.pathname === '/contact-us' ? 'active' : 'text-light'}`}>{t('nav_contact')} </Nav.Link>
              <Nav.Link as={Link} to='/products' className={`  ${location.pathname === '/products' ? 'active' : 'text-light'}`}>{t('nav_product')} </Nav.Link>
              <Nav.Link as={Link} to='/about' className={`  ${location.pathname === '/about' ? 'active' : 'text-light'}`}>{t('nav_about')} </Nav.Link>
              <Nav.Link as={Link} to='/' className={` ${location.pathname === '/' ? 'active' : 'text-light'}`}>{t('nav_home')}</Nav.Link>
              {/* <Nav.Link as={Link} to="/career" className={`nav-link ${location.pathname === '/career' ? 'active' : ''}`}>  الوظائف المتاحة</Nav.Link> */}
            </Nav>
          </div>
        </div>
      </div>
      <div className="SunNavbar">
        <div className='flex   w-100 flex-row gap-5 align-items-center nabar_side'>
          <Button rounded style={{ width: '35px', height: '35px' }} outlined text raised className='mr-2 ml-2' onClick={() => setIsOpen(!isOpen)} icon="pi pi-bars" size='small' />
          <Nav
            className="m-auto my-1 my-lg-0  navbar-primereact-sm "
            style={{ maxHeight: '100px' }}
          >

            <Nav.Link as={Link} to="/" className={` navbar-primereact-sm  nav-link ${location.pathname === '/' ? 'active' : ''}`}> <Icons.Home /> <span> {isLang === "en" ? 'Home' : 'الرئيسية'} </span></Nav.Link>
            {/* <Nav.Link as={Link} to="/career" className={`nav-link ${location.pathname === '/career' ? 'active' : ''}`}>  الوظائف المتاحة</Nav.Link> */}

          </Nav>
        </div>
      </div>


    </>
  );

  const end = <Avatar onClick={onAvatarClick} label={fetchPersonalData?.name?.charAt(0)?.toUpperCase()} size="mediam" style={{ backgroundColor: '#FFCC48', color: '#000' }} />

  const menuItems = [

    {
      label: <Link to="/"> {isLang === 'en' ? 'Back to home page' : ' الرجوع للصفحه الرئيسيه  '}</Link>,
      icon: 'pi pi-fw pi-user',
      command: () => {
        // LogOut(); 
      },
    },

    {
      label: <Link to="/"> {isLang === 'en' ? 'Logout' : 'تسجيل خروج  '}</Link>,
      icon: 'pi pi-fw pi-sign-out',
      command: () => {
        LogOut();
      },

    },
    { separator: true },
    {
      template: (item, options) => {
        return (
          <div className="details_users">
            <button onClick={(e) => options.onClick(e)} className={'w-full p-link flex align-items-center px-3 pb-3 pt-3'}>
              <Avatar image={`${fetchPersonalData?.image}`} className="mr-2" shape="circle" />
              <div className="flex flex-column align">
                <span className="font-bold">{fetchPersonalData?.name}</span>
                <span className="text-sm">{fetchPersonalData?.email}</span>
              </div>
            </button>
          </div>
        )
      }

    },
  ];
  return (
    <div className=" navbar-primereact w-full" dir={isLang === "en" ? 'ltr' : 'rtl'}>

      <Menubar end={<div className="p-menu-list	">{end}</div>} start={start} />
      <div className="menu_ofter_login">
        <TieredMenu
          ref={menuRef}
          model={menuItems}
          popup
          onHide={() => setShowMenu(false)}
          id="popup_menu"
          style={{ display: showMenu ? 'block' : 'none' }}
          className='mt-2'
        />
      </div>

    </div>
  )

}

export default Navber