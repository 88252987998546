import { Button, Col, Form, Input, Row, Typography } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOTPLogic } from './OTPLogic'; // Import the logic from a separate file

const { Title } = Typography;

const OTP = () => {
    const { t } = useTranslation();
    const { isLang } = useContext(LocalizationContext);
    const {
        formik,
        otp,
        handleChange,
        loademail,
        fetchResendOtp,
    } = useOTPLogic(); // Destructure the needed hooks and functions from the logic file
    const onChange = (value) => { 
        formik.setFieldValue('code', value);
    };
    const sharedProps = {
        onChange,
    };

    return (


        <Row className="vh-100 app__otp" dir={isLang === 'ar' ? 'rtl' : 'ltr'}  >
            <Col xl={10} lg={10} md={12} sm={12} className={`${isLang === "ar" ? 'login_overlayAr' : 'login_overlay'} login_overlay_sm   position-relative m-0 p-0`}   >
                <img src={img.AuthBg1} alt="" className='w-100 h-100 object-fit-cover' />
                <div className="overlay">
                    <img src={img.AuthBg2} alt="" className='w-100 object-fit-cover' />
                </div>
            </Col>
            <Col xl={14} lg={14} md={24} sm={24} className="app__auth h-100  flex flex-column justify-content-center align-items-center  ">
                <div className="app__login    flex justify-content-center align-items-center">
                    <div className="app__login-left">
                        <img loading="lazy" src={img.logoDark} className='object-fit-contain' alt="Login page background" />
                        <div className="login__form_inputs login__form_otp ">
                            <Form layout="vertical" onFinish={formik.handleSubmit} className='flex justify-content-center align-items-center flex-column'>
                                <Form.Item className='w-100' label={t('Email')} name="email" initialValue={localStorage.getItem('userEmail')}>
                                    <Input disabled size='large' onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        id="email"
                                        className='w-100'
                                        name='email'
                                    />
                                </Form.Item>
                                <Form.Item label={t('otp_label')} name="otp" rules={[{ required: true, message: t('otp_label_error') }]}>
                                    <Input.OTP length={6} onChange={handleChange} {...sharedProps} size='large' />
                                </Form.Item> 
                                <Button type="primary" htmlType="submit" loading={loademail} className='mt-4 ' block>{t('Verify')}</Button>
                            </Form>
                            <div className="app__now_account">
                                <span >   {t('otp_resend')} <Link onClick={fetchResendOtp} >   {t('otp_resend_code')}</Link></span>
                            </div>
                        </div>
                    </div>
                    <div >
                        <Link to={'/'} dir='ltr' className="btn_back cursor-pointer flex justify-content-center align-items-center gap-2">
                            <Icons.ArrowBtn />
                            <h6 className='m-0 p-0 text-dark'>{t('Back')}</h6>
                        </Link>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default OTP;
