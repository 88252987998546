import axios from 'axios';
import cookies from 'js-cookie';
import { createContext, useContext, useEffect, useState } from 'react';
import { LocalizationContext } from './LangChange';
export const SharedData = createContext([])

function CallApi({ children }) {
    let { isLang } = useContext(LocalizationContext);




    const [fetchSettings, setFetchSettings] = useState([]);
    const fetchSettingsData = async () => {
        const url = `https://holmz.rightclick.com.sa/api/home/settings`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next')
            }
        })
        if (data?.status === 200) {
            setFetchSettings(data?.data);
        }
    }

    const [fetchMostProducts, setFetchMostProducts] = useState([]);
    const fetchProductsData = async () => {
        const url = `https://holmz.rightclick.com.sa/api/home/most-selling-products?page=-1`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),

            }
        })
        if (data?.status === 200) {
            setFetchMostProducts(data?.data?.data); 
        }
    }

    const [fetchHome, setFetchHome] = useState([]);
    const fetchHomeData = async () => {
        const url = `https://holmz.rightclick.com.sa/api/home`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),

            }
        })

        if (data?.status === 200) {
            setFetchHome(data);
        }
    }

    const [fetchPersonalData, setFetchPersonalData] = useState([]);
    const fetchPersonal = async () => {
        const getcClientId = localStorage.getItem('token');
        if (getcClientId !== null) {
            const url = `https://holmz.rightclick.com.sa/api/auth/profile`;
            let { data } = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': cookies.get('i18next'),
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            })
            if (data?.status === 200) {
                setFetchPersonalData(data?.data);
            }
        }
    }

    const [cart, setCart] = useState([]);
    const fetchCart = async () => {
        const url = `https://holmz.rightclick.com.sa/api/cart`;
        if (localStorage.getItem('token')) {
            let { data } = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': cookies.get('i18next'),
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            })
            if (data?.status === 200) {
                setCart(data?.data);
                console.log(data?.data);
            }
        } else {
            const urlGuest = `https://holmz.rightclick.com.sa/api/cart?guest_id=${localStorage.getItem('guest_id')}`;
            let { data } = await axios.get(urlGuest, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': cookies.get('i18next'),
                }
            })
            if (data?.status === 200) {
                setCart(data?.data);
                console.log(data?.data);
            }
        }
    }

    const [favourite, setFavourite] = useState([]);
    const fetchFavourite = async () => {
        const url = `https://holmz.rightclick.com.sa/api/favourite`;
        if (localStorage.getItem('token')) {
            let { data } = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': cookies.get('i18next'),
                    Authorization: 'Bearer ' + localStorage.getItem('token'),

                }
            })
            if (data?.status === 200) {
                setFavourite(data?.data);
                console.log(data?.data);
            }

        }
    }

    useEffect(() => {
        fetchPersonal()
        fetchHomeData()
        fetchSettingsData()
        fetchProductsData()
        fetchCart()
        fetchFavourite()
    }, [isLang])

    return (
        <SharedData.Provider
            value={{
                fetchSettings,
                fetchMostProducts,
                fetchHome,
                cart,
                fetchCart,
                favourite,
                fetchFavourite,
                fetchPersonalData,
                fetchPersonal,
                fetchProductsData
            }}
        >
            {children}
        </SharedData.Provider>
    )
}

export default CallApi