import { Player } from '@lottiefiles/react-lottie-player';
import { Rate } from 'antd';
import axios from 'axios';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cartEmpty from '../../../assets/cart.json';
const CartPersonal = () => {
  let { fetchHome, fetchCart } = useContext(SharedData)
  let { fetchFavourite, fetchProductsData } = useContext(SharedData)

  let { t } = useTranslation();
  let { cart } = useContext(SharedData)
  const [isFavouriteActive, setIsFavouriteActive] = useState(false);
  const [sizeActive, setSizeActive] = useState(true);
  let navigate = useNavigate()

  const toggleFavourite = async (item) => {
    console.log(item);
    if (item?.in_favourite == 0) {
      console.log('0');
      const url = `https://holmz.rightclick.com.sa/api/favourite/add`;
      let { data } = await axios.post(url, { product_id: item.product_id }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),

        }
      })
      console.log(data);
      if (data?.status === 200) {
        toast.success(data?.message)
        fetchFavourite()
        fetchProductsData()
        fetchCart()
      }

    } else if (item?.in_favourite == 1) {
      console.log('1');

      const url = `https://holmz.rightclick.com.sa/api/favourite/${item.product_id}/delete`;
      let { data } = await axios.delete(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      })
      if (data?.status === 200) {
        fetchFavourite()
        fetchCart()
        fetchFavourite()

        toast.success(data?.message)

      }
    }
  };

  const deleteProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/cart/${id}/delete`;
    const url_guest_id = `https://holmz.rightclick.com.sa/api/cart/${id}/delete?guest_id=${localStorage.getItem('guest_id')}`;
    if (localStorage.getItem('guest_id')) {
      let { data } = await axios.delete(url_guest_id, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),
        }
      })
      if (data?.status === 200) {
        fetchCart()
        toast.success(data?.message)
      }
    } else {
      let { data } = await axios.delete(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),

        }
      })
      console.log(data);
      if (data?.status === 200) {
        fetchCart()
        toast.success(data?.message)
      }

    }
  }


  const deleteAllProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/cart/destroy`;
    const url_guest_id = `https://holmz.rightclick.com.sa/api/cart/destroy?guest_id=${localStorage.getItem('guest_id')}`;


    if (localStorage.getItem('guest_id')) {
      let { data } = await axios.get(url_guest_id, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),

        }
      })
      if (data?.status) {
        fetchCart()
        navigate('/')
        toast.success(data?.message)
      }
    } else {
      let { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),

        }
      })
      if (data?.status) {
        fetchCart()
        navigate('/')
        toast.success(data?.message)
      }
    }
  }
  return (
    <div className='card-personal'>
      <div className='app_cart '>

        <div className="container_cart">
          <header className='app_cart_header'>
            <div className="Contact-header  flex flex-row gap-6 justify-content-center w-100">
              <div className={`app_card  bg-transparent `} dir='rtl'>
                <Icons.Card />
                <span>{cart?.length}</span>

              </div>
              <h2 className='text-center '>Cart</h2>
            </div>

            <div className="icons ms-auto cursor-pointer" onClick={() => deleteAllProduct()}>
              <Icons.Close />
            </div>
          </header>
          {
            cart?.length > 0 ?
              <body className='bg-transparent  app_cart_lg'>
                {
                  cart?.map((item, index) => (
                    <section className=' item mt-4 rounded-2   m-auto p-5 '>
                      <Row className=' '>
                        <Col xl={3} lg={3} md={6} sm={12} className='item_image flex justify-content-center'>
                          <img src={item.image} className='w-100 object-fit-contain' />
                        </Col>
                        <Col xl={9} lg={9} md={6} sm={12} className='item_icons_close'>
                          <div className="item_details">
                            <div className="flex justify-content-between align-items-center">
                              <div className="header">
                                <span>product name</span>
                                <h3>{item?.name}</h3>
                              </div>

                              <div className="favourite">
                                <Button
                                  severity={item.in_favourite === 1 ? "primary" : "secondary"}
                                  icon="pi pi-heart"
                                  onClick={() => toggleFavourite(item)}
                                />
                              </div>
                            </div>

                            <div className="rate flex gap-2 align-items-center">
                              <span>4.2</span> 
                              <Rate disabled value={item?.average_rate} />

                            </div>

                            <div className="flex justify-content-between align-items-center ">

                              <div className="footer mt-4">
                                <span>about project</span>
                                <p className='m-0 p-0 w-75' >{item?.shot_description}</p>
                              </div>

                              <div className={`${sizeActive ? "size" : '  size sizeActive'}  cursor-pointer`} onClick={() => setSizeActive(!sizeActive)}>
                                <span>{item?.size} </span>
                                <h4>{item?.price}  </h4>

                                <div className="footer flex gap-3">
                                  <span>{item?.size}<sub>mg</sub></span>
                                  <span>{item?.price}<sub>s</sub></span>
                                </div>
                              </div>

                            </div>
                          </div>

                          <div className="icons ms-auto cursor-pointer" onClick={() => deleteProduct(item?.id)}>
                            <Icons.Close />
                          </div>
                        </Col>
                      </Row>
                    </section>
                  ))
                }

                <div className="checkout_btn mt-8 w-100  flex justify-content-center">
                  <Button label='checkout now' />
                </div>
              </body> :
              <div className="Player">
                <Player
                  className='w-50'
                  src={cartEmpty}
                  autoplay
                  loop
                />
              </div>
          }

          <div className="app__cart_sm">


            {
              cart?.map((item, index) => (
                <>
                  <Row className='mt-3 app__cart_sm_item'>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4} className=' flex justify-content-center '>
                      <img src={img.BgPerfume2} className='w-100 object-fit-contain' alt="" srcset="" />
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                      <div className="header">
                        <span>{item?.name}</span>
                        <h3 className='mt-2'>{item?.shot_description} {" - "} <small className=''>{item?.size} <sub>mg</sub> </small> </h3>
                        <h2 className='mt-4'>{item?.price} ريال</h2>
                      </div>
                    </Col>

                    <div className="btn_cart_sm flex justify-content-between">
                      <button onClick={() => deleteProduct(item.id)} class="button-13" role="button"><i className='pi pi-trash'></i></button>
                      <button onClick={() => toggleFavourite(item.id)} class="button-13" role="button"><i className={`pi ${item?.in_favourite === 1 && 'pi-favorite'}  pi-heart-fill`}></i></button>
                    </div>

                  </Row>
                </>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartPersonal
