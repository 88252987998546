import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Rate } from 'antd';

import Icons from 'constants/Icons';

const ProductItem = ({ item, sizeActive, setSizeActive, toggleFavourite, deleteProduct }) => (
  <section className='item mt-4 rounded-2 m-auto p-5'>
    <Row>
      <Col xl={3} lg={3} md={6} sm={12} className='item_image flex justify-content-center'>
        <img src={item.image} className='w-100 object-fit-contain' />
      </Col>
      <Col xl={9} lg={9} md={6} sm={12} className='item_icons_close'>
        <div className="item_details">
          <div className="flex justify-content-between align-items-center">
            <div className="header">
              <span>product name</span>
              <h3>{item.name}</h3>
            </div>
            <div className="favourite">
              <Button
                severity={item.in_favourite === 1 ? "primary" : "secondary"}
                icon="pi pi-heart"
                onClick={() => toggleFavourite(item)}
              />
            </div>
          </div>
          <div className="rate flex gap-2 align-items-center">
            <span>4.2</span>
            <Rate disabled value={item.average_rate} />
          </div>
          <div className="flex justify-content-between align-items-center">
            <div className="footer mt-4">
              <span>about project</span>
              <p className='m-0 p-0 w-75'>{item.shot_description}</p>
            </div>
            <div className={`${sizeActive ? "size sizeActive" : 'size sizeActive'} cursor-pointer`} onClick={() => setSizeActive(!sizeActive)}>
              <span>{item.size}</span>
              <h4>{item.price}</h4>
              <div className="footer flex gap-3">
                <span>{item.size}<sub>mg</sub></span>
                <span>{item.price}<sub>s</sub></span>
              </div>
            </div>
          </div>
        </div>
        <div className="icons ms-auto cursor-pointer" onClick={() => deleteProduct(item.id)}>
          <Icons.Close />
        </div>
      </Col>
    </Row>
  </section>
);

export default ProductItem;
