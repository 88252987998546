
import PersonalData from 'Pages/Admin/PersonalData/PersonalData.jsx';
import AboutHome from 'Pages/Website/Home/AboutHome';
import ExploreHome from 'Pages/Website/Home/Explore/ExploreHome';
import Home from 'Pages/Website/Home/Home';
import Product from 'Pages/Website/Home/product/Product';
import Cards from 'Pages/Website/Product/Cards/Cards.jsx';
import Favorite from 'Pages/Website/Product/Favorite/Favorite';
import ProdactDetails from 'Pages/Website/Product/ProdactDetails/ProdactDetails';
import Agent from 'Pages/Website/contact-us/Agent';
import ChangePasword from 'Pages/Admin/PersonalData/ChangePasword';
import EditAccount from 'Pages/Admin/PersonalData/EditAccount';
import Login from 'Pages/Auth/Login/Login';
import Register from 'Pages/Auth/Register/Register';
import OTP from 'Pages/Auth/Settings/OTP';
import ResetPassword from 'Pages/Auth/Settings/ResetPassword';
import About from 'Pages/Website/About/About';
import Products from 'Pages/Website/Product/Products';
import Contact from 'Pages/Website/contact-us/Contact';
import { Footer } from 'components/Footer/Footer';
import NavBar from 'components/Navbar/NavBar';
import Navber from 'components/Sidebar/Navber/Navber';
import SidebarSM from 'components/Sidebar/SidebarSM';
import Sidebar from 'components/Sidebar/Sildebar';
import Message from 'components/SocialMedia/Message';
import Account from 'layout/Account';
import Auth from 'layout/Auth';
import Holmes from 'layout/Holmes';
import CartPersonal from 'Pages/Admin/Cart/CartPersonal';
import FavoritesPersonal from 'Pages/Admin/Favorites/FavoritesPersonal';


const Component = {
  // Layout
  Holmes,
  NavBar,
  Footer,
  Home,
  AboutHome,
  ExploreHome,
  Product,
  Auth,
  Login,
  Register,
  About,
  Products,
  Agent,
  Contact,
  Message,
  OTP,
  ResetPassword,
  ProdactDetails,
  Cards,
  Favorite,
  Navber,
  SidebarSM,
  Sidebar,
  Account,
  PersonalData,
  EditAccount,
  ChangePasword,
  CartPersonal,
  FavoritesPersonal
}

export default Component


