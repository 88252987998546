import { Button } from 'primereact/button';
import { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import './style.scss';

const PersonalData = () => {
    let { isLang } = useContext(LocalizationContext);
    let { fetchPersonalData } = useContext(SharedData)
    useEffect(() => {
      window.scroll(0, 0)
    }, [])
  
  return (
    <div className="app__PersonalData">

    <div className="header">
      <div className="img relative ">

        <div className={`personal  type_normal`}>
          {
            fetchPersonalData.image ?
              <img src={`${fetchPersonalData.image}`} className='w-100' alt="images" loading='lazy' /> :
              <img src={img.personal} className='w-100' alt="images" loading='lazy' />
          }
        </div>
   

      </div>
      <div className="Edite">
        <Link
          to={`/account/edit`} state={{ data: fetchPersonalData }}         >
          <Button icon='pi pi-cog' label={isLang === "en" ? 'Edit profile' : 'تعديل الحساب '} />
        </Link>
      </div>
    </div>
    <div className="info mt-35">
      <h3 className='text-capitalize'>{fetchPersonalData?.name}</h3> 
    </div> 

    <div className="info_contactus mt-35">
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} className='mt-4 '>
          <div className="conatent">
            <div className="icons">
              <Icons.CallFill />
            </div>
            <div>
              <div className="header"> {isLang === "en" ? 'Mobile number' : 'رقم الجوال '}   </div>
              <div className="body" dir='ltr'> {fetchPersonalData?.phone}   </div>
            </div>
          </div>
        </Col>
 
        <Col xl={6} lg={6} md={6} sm={12} className='mt-4 '>
          <div className="conatent ">
            <div className="icons">
              <Icons.Email />
            </div>
            <div>
              <div className="header">{isLang === "en" ? 'E-mail' : '   البريد الالكترونى   '}  </div>
              <div className="body"> {fetchPersonalData?.email}  </div>
            </div>
          </div>
        </Col>
        
      </Row>
    </div>

  </div>
  )
}

export default PersonalData