import { CheckOutlined, GlobalOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { LocalizationContext } from 'context/LangChange';
import { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NavDrop = ({ token, fetchPersonalData, handleAvatarClick, handleLanguageChange, languages, showMenu, setShowMenu, isLang, currLangCode }) => {
    const { t } = useTranslation();
    let { LogOut } = useContext(LocalizationContext);

    const menu = (
        <Menu>
            <Menu.Item key="profile" icon={<UserOutlined />}>
                <Link to="/account/personal_data">{isLang === 'en' ? 'Profile' : 'الملف الشخصي'}</Link>
            </Menu.Item>
            <Menu.Item key="logout" onClick={LogOut} icon={<LogoutOutlined />}>
                <Link>{isLang === 'en' ? 'Logout' : 'تسجيل خروج'}</Link>
            </Menu.Item>
            <Menu.SubMenu key="changeLang" icon={<GlobalOutlined />} title={isLang === 'en' ? 'Change Lang' : 'تغير اللغة'}>
                {languages.map(({ code, name, country_code }) => (
                    <Menu.Item
                        key={code}
                        onClick={() => handleLanguageChange(code)}
                        icon={currLangCode == code ? <CheckOutlined /> : null}
                    >
                        {name}
                    </Menu.Item>
                ))}
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.Item key="userDetails">
                <Link to="/account/personal_data" className="details_users flex flex-row">
                    <Avatar src={fetchPersonalData?.image} className="mr-2" shape="circle" size="large" />
                    <div className="flex flex-column align">
                        <span className="font-bold text-capitalize">{fetchPersonalData?.name}</span>
                        <span className="text-sm">{fetchPersonalData?.email}</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Nav.Item className='flex justify-content-between align-items-center gap-5'>
            {token ? (
                <Dropdown overlay={menu} trigger={['click']} visible={showMenu} onVisibleChange={setShowMenu}>
                    <Avatar onClick={handleAvatarClick} src={fetchPersonalData?.image ? fetchPersonalData.image : 'https://api.dicebear.com/7.x/miniavs/svg?seed=11'}
                        className="mr-2 cursor-pointer" shape="circle" size="large" />
                </Dropdown>
            ) : (
                <Nav.Item className={`position-relative flex flex-row justify-content-center align-items-center gap-1 w-100 ${isLang === "en" ? 'login_line' : 'login_lineAr'}`}>
                    <Nav.Link as={Link} to='/holmes/sign-in' className={`text-light ${isLang === "en" ? 'login_wEn' : 'login_wAr'}`}>{t('login')}</Nav.Link>
                    <Nav.Link as={Link} to='/holmes/sign-up' className={`text-light ${isLang === "en" ? 'login_wEn' : 'login_wAr'}`}>{t('sign_up')}</Nav.Link>
                </Nav.Item>
            )}
            <div className="language-select">
                <div className="d-flex justify-content-end align-items-center language-select-root">
                    <div className={`dropdown ${isLang === "ar" ? 'dropdown_dirAr' : 'dropdown_dir'}`}>
                        <button
                            className="btn btn-link dropdown-toggle flex flex-row justify-content-center align-items-center gap-2 text-light"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span className={`flag-icon flag-icon-${t('country_code')} mx-2`}></span>
                            <span className="dropdown-item-text">{t('language')}</span>
                        </button>
                        <ul className="dropdown-menu rounded-2" aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name, country_code }) => (
                                <li key={country_code}>
                                    <a
                                        style={{ background: currLangCode !== code ? '' : ' #FFCC48' }}
                                        href="#"
                                        className='dropdown-item flex'
                                        onClick={() => handleLanguageChange(code)}
                                        dir={isLang !== "ar" ? 'rtl' : 'ltr'}
                                    >
                                        <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                                        {name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Nav.Item>
    );
};

export default NavDrop;
