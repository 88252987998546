import React from 'react'
import Component from 'constants/Component'
import { Outlet } from 'react-router-dom'

const Holmes = () => {
  return (
    <div>
      <Component.NavBar   />
      <div style={{ position: 'relative' }}  >
        <Outlet></Outlet>
      </div>
      <Component.Footer/>
    </div>
  )
}

export default Holmes