import { ReactComponent as AboutUs } from '../assets/SVG/aboutus.svg';
import { ReactComponent as ArrowRight } from '../assets/SVG/arrow-right.svg';
import { ReactComponent as ArrowTop } from '../assets/SVG/arrow-top.svg';
import { ReactComponent as CallOutlite } from '../assets/SVG/call.svg';
import { ReactComponent as CallFill } from '../assets/SVG/callFill.svg';
import { ReactComponent as Email } from '../assets/SVG/email.svg';
import { ReactComponent as Facebook } from '../assets/SVG/facebookplusLight.svg';
import { ReactComponent as Instagram } from '../assets/SVG/instagram.svg';
import { ReactComponent as Language } from '../assets/SVG/language.svg';
import { ReactComponent as location } from '../assets/SVG/location.svg';
import { ReactComponent as Phone } from '../assets/SVG/phone.svg';
import { ReactComponent as ProductIcons } from '../assets/SVG/product.svg';
import { ReactComponent as Twitter } from '../assets/SVG/twitter.svg';
import { ReactComponent as Whatsapp } from '../assets/SVG/whatsapp.svg'; 
import { ReactComponent as ArrowOutlit } from '../assets/SVG/arrow-outlit.svg';
import { ReactComponent as CloseBtn } from '../assets/SVG/Close.svg';  
import { ReactComponent as Home } from '../assets/SVG/homelight.svg';
import { ReactComponent as MediaImg } from '../assets/SVG/media.svg'; 
import { ReactComponent as DoneP } from '../assets/SVG/Done.svg';
import { ReactComponent as Linkedin } from '../assets/SVG/linkedin.svg'; 
import { ReactComponent as Youtube } from '../assets/SVG/youtube.svg';
import { ReactComponent as Card } from '../assets/SVG/card.svg'; 
import { ReactComponent as ArrowBtn } from '../assets/SVG/arrow.svg'; 
import { ReactComponent as Close } from '../assets/SVG/Close.svg'; 
import { ReactComponent as Favorite } from '../assets/SVG/favorite.svg'; 
import { ReactComponent as Login } from '../assets/SVG/login.svg'; 
import { ReactComponent as Blogs } from '../assets/SVG/Blogs.svg'; 
import { ReactComponent as Camera } from '../assets/SVG/Camera.svg'; 


const Icons = {
    Facebook,
    Camera,
    Blogs,
    Card,
    Email,
    Login,
    ArrowBtn,
    Close,
    Youtube,
    DoneP, 
    AboutUs, 
    Instagram,
    Language,
    Linkedin,
    Phone,
    Twitter,
    ArrowRight,
    ArrowTop,
    MediaImg,
    Whatsapp,
    location,
    CallOutlite, 
    ProductIcons, 
    ArrowOutlit, 
    Home,     
    CloseBtn,
    CallFill,
    Favorite
}

export default Icons;
