import React, { useCallback } from 'react';
import useProdactDetails from './useProdactDetails';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Col, Row } from 'react-bootstrap';
import { Carousel, Rate } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './details.scss';

const ProdactDetails = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const {
    isFavouriteActive,
    setIsFavouriteActive,
    price,
    setPrice,
    sizeActive,
    setSizeActive,
    Index,
    setIndex,
    images,
    data,
    value2,
    setValue2,
    toggleFavourite,
    addCart,
  } = useProdactDetails(id);



  const changePrice = useCallback((item) => { 
    setSizeActive(item.id)
    setPrice(item.price)
    console.log(price);
  }, [sizeActive, price]);
  return (
    <div>
      <div className="app__Product_details mb-8 ">
        <div className="slider_hover">
          <div className='prodact_details_container'>
            <Row className='card__image  mb-5'>
              {
                images &&
                <>
                  <Col xl={2} lg={2} className="small-images-container h-100 card__image_lg">
                    {images?.slice(0, 3).map((item, i) => (
                      item && item ? (
                        <img
                          className={i === Index ? 'small-image selected-image p-2' : 'small-image'}
                          key={i}
                          src={item}
                          onMouseEnter={() => setIndex(i)}
                          style={{ objectFit: 'contain', objectPosition: '50% 50%', width: '141px', height: '141px' }}
                          alt='image'
                        />
                      ) : null
                    ))}
                  </Col>
                  <Col xl={5} lg={5} className="product-detail-image p-5 card__image_lg">
                    {images?.length === 1 ?
                      <img
                        src={images[0]}
                        alt='product detail '
                        style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                        className='w-100 product-detail-images'
                      /> :
                      <img
                        src={images && images[Index]}
                        alt='product detail'
                        style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                        className='w-100 product-detail-images'

                      />
                    }
                  </Col>
                  <div className="card__image_sm">
                    <Carousel autoplay infinite={true} speed={2000} swipeToSlide={true} /* afterChange={onChange} */>
                      {
                        images?.map((item) => (
                          <div className='slide-container flex justify-content-center align-items-center  '>
                            <img src={item} alt="" className="cart_images" />
                          </div>
                        ))
                      }
                    </Carousel>
                  </div>


                  <Col xl={5} lg={5}>
                    <div className='view_details' >
                      <div className="item_details">
                        <div className="flex justify-content-between align-items-center">
                          <div className="header">
                            <span className='details_span'>product name</span>
                            <h3>{data.name}</h3>
                          </div> 
                          <div className="favourite">
                            <Button
                              severity={data?.in_favourite === 1 ? "primary" : "secondary"}
                              icon="pi pi-heart"
                              size='small'
                              onClick={() => toggleFavourite(id, data?.in_favourite)}
                            />
                          </div>
                        </div>

                        <div className="rate flex gap-2 align-items-center">
                          <span className='rate_title'>{data?.average_rate}</span>
                          <Rate disabled value={data?.average_rate} />
                        </div>

                      </div>
                      <div className="dec mt-4">
                        <span className='details_span'>about project</span>
                        <p className='m-0 p-0   '>{data?.shot_description}</p>
                      </div>

                      <div className="size-title">
                        <span className='mb-5'>size</span>
                        <div className="flex flex-row gap-4">
                          {data?.sizes?.map((item, index) => (
                            <div key={index} className={`${Number(sizeActive) !== Number(item.id) ? "size" : '  size sizeActive'}  cursor-pointer`}
                              onClick={() => {
                                changePrice(item) 
                              }}
                            >
                              <span className='details_span'>{item?.name}</span>
                              <h4>{item?.price}</h4>
                              <div className="footer flex gap-3">
                                <span className='details_span'>{item?.name}<sub>mg</sub></span>
                                <span className='details_span'>{price}<sub>SAR</sub></span>
                              </div>
                            </div>))}
                        </div>
                      </div>
                      <div className="btn_details_content  ">
                        <div className="siz_btn">
                          <span className='details_span'>quantity</span>
                          <div className="flex-auto mt-5">
                            <InputNumber value={value2}
                              onValueChange={(e) => setValue2(e.value)}
                              showButtons buttonLayout="horizontal"
                              decrementButtonClassName="p-button-secondary"
                              incrementButtonClassName="p-button-secondary"
                              incrementButtonIcon="pi pi-plus"
                              decrementButtonIcon="pi pi-minus"
                              min={1}
                            />
                          </div>
                        </div>
                        <div className="total">
                          <span>Total</span>
                          <h3 className='mt-5'>{price * value2} <sub>SAR</sub></h3>
                        </div>
                      </div>

                      <div className="checkout_btn mt-8 w-100 flex flex-column gap-4 justify-content-center">
                        <Button label='checkout now' className='w-100' size='sm' />
                        <div className="checkout_btn_cart">
                          <Button label='Add to cart' outlined icon='pi pi-tags' size='sm' className='w-100' onClick={() => addCart(id)} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              }
            </Row>


          </div>
        </div>
      </div>
    </div>
  )
};

export default ProdactDetails;
