// RegisterLogic.js
import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showNotification } from 'utils/notification';

export const useRegisterLogic = () => {
    const [loademail, setLoadEmail] = useState(false);
    const { t } = useTranslation();

    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
 
     const navigate = useNavigate(); 

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            password: ''
        },
    
        onSubmit: async (values) => {
            setLoadEmail(true); 
            try {
                let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/register`, values);
                if (data?.status === 200) {
                    localStorage.setItem('token', data?.data?.token);
                    setTimeout(() => {
                        navigate(`/holmes/otp`);
                        localStorage.setItem('userEmail', values.email);
                    }, 500);
                    setLoadEmail(false);
                    console.log();
                    showNotification('success', t('register_msg_success'),data?.message)

                }
            } catch (error) {
                if (error?.response?.status === 422) {
                    setLoadEmail(false);
                    showNotification('error', t('register_msg_error'), error?.response?.data?.message)
                    if (error?.response?.data?.errors?.phone) {
                        showNotification('warning', 'warning', error?.response?.data?.errors?.phone)
                    }
                    if (error?.response?.data?.errors?.email) {
                        showNotification('warning', 'warning', error?.response?.data?.errors?.email)
                    }
                    if (error?.response?.data?.errors?.password) {
                        showNotification('warning', 'warning', error?.response?.data?.errors?.password)
                    }
                }
            }
        }
    });
    return {
        formik,
        loademail,
        setLoadEmail,
        handelOpenPassword,
        setHandelOpenPassword,  
    };
};
