import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import { default as Cookies } from 'js-cookie';
import toast from 'react-hot-toast';

const useProdactDetails = (id) => {
  const [isFavouriteActive, setIsFavouriteActive] = useState(false);
  const [sizeActive, setSizeActive] = useState('');
  const [price, setPrice] = useState(null);
  const [Index, setIndex] = useState(1);
  const [images, setImages] = useState([]);
  const [data, setData] = useState([]);
  const [value2, setValue2] = useState(1);
  let { fetchFavourite, fetchCart } = useContext(SharedData);
  let { isLang } = useContext(LocalizationContext);

  const fetchProducts = async () => {
    let { data } = await axios.get(`https://holmz.rightclick.com.sa/api/home/products/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': Cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    });
    setData(data?.data);
    setImages(data?.data?.images);
    if (data?.data?.sizes) {
      setSizeActive(data?.data?.sizes[0].id);
      setPrice(data?.data?.sizes[0].price);
    }
  };

  const toggleFavourite = async (id, type) => {
    if (type === 0) {
      const url = `https://holmz.rightclick.com.sa/api/favourite/add`;
      let { data } = await axios.post(url, { product_id: id }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': Cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });

      if (data?.status === 200) {
        toast.success(data?.message);
        fetchFavourite();
        fetchProducts();
      }
    } else if (type === 1) {
      deleteProduct(id);
    }
  };

  const deleteProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/${id}/delete`;
    let { data } = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': Cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    });
    if (data?.status === 200) {
      fetchFavourite();
      fetchProducts();
      toast.success(data?.message);
    }
  };

  const addCart = async (id) => {
    setIsFavouriteActive(!isFavouriteActive);
    const url = `https://holmz.rightclick.com.sa/api/cart/add`;
    if (localStorage.getItem('token')) {
      let { data } = await axios.post(url, {
        product_id: id,
        size_id: sizeActive,
        quantity: value2
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': Cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });

      if (data?.status === 200) {
        toast.success(data?.message);
        fetchCart();
      }
    } else {
      let { data } = await axios.post(url, {
        guest_id: localStorage.getItem('guest_id'),
        product_id: id,
        size_id: sizeActive,
        quantity: value2
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': Cookies.get('i18next'),
        }
      });

      if (data?.status === 200) {
        toast.success(data?.message);
        fetchCart();
      }
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    const timeOut = setTimeout(() => {
      fetchProducts()
    }, 200);
    return () => clearTimeout(timeOut);
  }, [id, isLang]);

  return {
    isFavouriteActive,
    setIsFavouriteActive,
    sizeActive,
    setSizeActive,
    Index,
    setIndex,
    images,
    data,
    value2,
    setValue2,
    toggleFavourite,
    addCart,
    price,
    setPrice,
  };
};

export default useProdactDetails;
