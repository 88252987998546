import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { showNotification } from 'utils/notification';
import { useTranslation } from 'react-i18next';

export const useOTPLogic = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [loademail, setLoadEmail] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        formik.setFieldValue('email', localStorage.getItem('userEmail'));
    }, []);

    const formik = useFormik({
        initialValues: {
            email: localStorage.getItem('userEmail') || '', 
            code: ''
        },
        onSubmit: async (values) => {
            try {
                setLoadEmail(true);
                const { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/verify-otp`, values);
                if (data.status === 200) {
                    await localStorage.setItem('token', data?.data?.token);
                    await navigate('/');
                    await showNotification('success', t('success'), t('otp_verification_success'));
                    await window.location.reload(); 
                } else {
                    showNotification('error', t('failed'), t('otp_verification_failed_message'));
                }
                setLoadEmail(false);
            } catch (error) {
                showNotification('error', t('failed'), t('otp_verification_failed'));
                setLoadEmail(false);
            }
        }
    });

    const handleChange = (value) => {
        setOtp(value);
        formik.setFieldValue('code', value);
    };

    const fetchResendOtp = async () => {
        const response = await axios.post(`https://holmz.rightclick.com.sa/api/auth/resend-otp`, { email: localStorage.getItem('userEmail') });
        if (response.data.status === 200) {
            showNotification('success', t('success'), response.data.message);
        } else {
            showNotification('error', t('failed'), response.data.message);
        }
    };

    return {
        formik,
        otp,
        handleChange,
        loademail,
        fetchResendOtp,
    };
};
