import Logo from "assets/Images/Logo.png";
import logoDark from "assets/Images/logoDark.png";
import facebook from 'assets/Images/facebook.png';
import linkedin from 'assets/Images/linkedin (1).png';
import twitter from 'assets/Images/twitter.png';
import youtube from 'assets/Images/youtube.png';
import slider from 'assets/Images/slider.png';

import perfume1 from 'assets/Images/home/perfume1.png';
import perfume3 from 'assets/Images/home/perfume3.png';
import perfume2 from 'assets/Images/home/perfume2.png';
import perfume4 from 'assets/Images/home/perfume4.png';
import BgPerfume1 from 'assets/Images/home/BgPerfume1.png';
import BgPerfume2 from 'assets/Images/home/BgPerfume2.png';
import BgPerfume3 from 'assets/Images/home/BgPerfume3.png';
import BgPerfume4 from 'assets/Images/home/BgPerfume4.png';
import BgPerfume5 from 'assets/Images/home/BgPerfume5.png';
import banner from 'assets/Images/home/banner.png';
import banner2 from 'assets/Images/home/banner2.png';
import Banner_footer from 'assets/Images/home/banner-footer.png';
import FooterBg from 'assets/Images/FooterBg.png';
import AuthBg1 from 'assets/Images/AuthBg1.png';
import AuthBg2 from 'assets/Images/AuthBg2.png';
import AuthBg3 from 'assets/Images/About/aboutBg.png';
import Stage1 from 'assets/Images/About/1th.png';
import Stage2 from 'assets/Images/About/2th.png';
import Stage3 from 'assets/Images/About/3th.png';
import Stage4 from 'assets/Images/About/4th.png';
import Agentbg from 'assets/Images/new agent.png';
import LogoLg from 'assets/Images/Logo2.png';
import item1 from 'assets/Images/item1.png';
import item2 from 'assets/Images/item2.png';
import item3 from 'assets/Images/item3.png';
import personal from 'assets/Images/Saudi Avatar (1).jpg';


const img = {
    Logo,
    LogoLg,
    facebook,
    logoDark,
    linkedin,
    AuthBg1,
    AuthBg2,
    AuthBg3,
    twitter,
    slider,
    youtube,
    perfume1,
    perfume3,
    perfume2,
    perfume4,
    banner,
    banner2,
    BgPerfume1,
    BgPerfume2,
    BgPerfume3,
    BgPerfume4,
    BgPerfume5,
    Banner_footer,
    FooterBg,
    Stage1,
    Stage2,
    Stage3,
    Stage4,
    Agentbg,
    item1,
    item2,
    item3,
    personal
}

export default img;
