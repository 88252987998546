import Icons from "../../constants/Icons";

 

const routes = [
  {
    path: "/account/personal_data",
    nameEn: "Profile",
    nameAr: "  البيانات الشخصية",
    icon: <i className="pi pi-user"></i>,

  },

  {
    path: "/account/cart",
    nameEn: "Cart",
    nameAr: "العربه",
    icon: <i className="pi pi-user"></i>,

  },

  {
    path: "/account/favorites",
    nameEn: "favorites",
    nameAr: "المفضله",
    icon: <i className="pi pi-user"></i>,

  },


  // {
  //   path: "/account/points",
  //   nameEn: "Points",
  //   nameAr: "  النقاط",
  //   icon: <Icons.AboutUs/>,
    
  // },


 /*  {
    path: "/account/delivery_address",
    nameEn: "  Delivery addresses",
    nameAr: "  عناوين التوصيل ",
    icon: <Icons.Location className="logoSvg" style={{ width: 17 }} />,
  }, */


  // {
  //   nameAr: "حجوزاتى   ",
  //   nameEn: "  Bookings ",
  //   icon: <Icons.DeliveryAddress className="logoSvg" style={{ width: 17 }} />, Roles: [1],
  //   subRoutes: [
  //     {
  //       path: "/account/manage",
  //       nameAr: "إدارة حجوزاتى  ",
  //       nameEn: "Manage My booking  ",
  //       icon: <Icons.Aboutus className="logoSvg" style={{ width: 17 }} />,
  //     },
  //     {
  //       path: "/account/bookings",
  //       nameAr: " الحجوزات السابقة  ",
  //       nameEn: "Last Bookings",
  //       icon: <Icons.Aboutus className="logoSvg" style={{ width: 17 }} />,
  //     },
  //   ],
  // },
];

export default routes