import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Validate From Fields
import * as Yup from 'yup';
import { LocalizationContext } from '../context/LangChange';
const useValidation = () => {
    let { isLang } = useContext(LocalizationContext)
    let { t } = useTranslation()
    const validateRegisterForm = () => {
        let validation = Yup.object({
            name: Yup.string().required(t('validate_usernama_required')),
            phone: Yup.string().required(t('validate_phone_required')),
            email: Yup.string().required(t('validate_email_required')).email(t('validate_email')),
            password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9]).{6,25}$/,
                    isLang === "en"
                        ? 'The password must contain an uppercase letter, a lowercase letter, a symbol, a number, and be between 6 and 25'
                        : "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورمز ورقم ويتكون بين 8 و 25  "
                ),
        })
        return validation
    }

    const validateLoginForm = () => {
        let validation = Yup.object({
            email: Yup.string().required(t('validate_email_required')).email(t('validate_email')),
            password: Yup.string().required(t('validate_password_required'))/* .matches(/^[A-Z][a-z0-9]{0,10}$/, t('validate_password')) */,
        })
        return validation
    }

    const validaterOTPForm = () => {
        let validation = Yup.object({
            email: Yup.string().required(t('validate_email_required')).email(t('validate_email')),
            code: Yup.string().required(t('validate_email_required')),
        })
        return validation
    }

    const validateChangePassword = () => {
        let validation = Yup.object({
            old_password: Yup.string().required(isLang === "en" ? 'Old password required' : 'كلمة المرور القديمة مطلوبة'),
            new_password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9]).{8,25}$/,
                    isLang === "en"
                        ? 'The password must contain an uppercase letter, a lowercase letter, a symbol, a number, and be between 8 and 25'
                        : "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورمز ورقم ويتكون بين 8 و 25  "
                ),
            password_confirmation: Yup.string().required(isLang === "en" ? 'Password confirmation required' : 'تأكيد كلمة المرور مطلوب').oneOf([Yup.ref('new_password'), null], isLang === "en" ? 'Password and confirm password must match' : 'كلمة المرور وتأكيد كلمة المرور يجب أن تتطابق'),
        })
        return validation
    }
    return { validateLoginForm, validateRegisterForm, validaterOTPForm, validateChangePassword }
}

export default useValidation
