import img from 'constants/Img'

const LogoNav = ({scroll}) => {
    return (
        <div>
            {scroll > 600 ?
                <div className="nav_logo_lg     flex  justifyalign-content-center  align-items-center">
                    <img src={img.Logo} style={{ width: '50px', height: '50px' }} alt="" className='rotateLogo' />
                </div> :
                <div className="nav_logo_lg   flex  justifyalign-content-center  align-items-center">
                    <img src={img.LogoLg} alt="" srcset="" />
                </div>
            }
        </div>
    )
}

export default LogoNav