import React from 'react';
import { Outlet } from 'react-router-dom';

const Auth = () => { 
  return (
    <>
      <div className="app__auth overflow-y-scroll overflow-x-hidden  "  >
        <Outlet></Outlet>
      </div>
    </>
  )
}

export default Auth