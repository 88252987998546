import { Box } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Component from 'constants/Component';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import './contact.scss';

const Agent = () => {
    let { t } = useTranslation()
    let { isLang } = useContext(LocalizationContext);
    const msgs = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? 'you name is required' : 'يرجى إدخال الاسم'),
        email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
        phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
        reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
        message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
    });

    useEffect(() => {
        window.scroll(0, 0)
        document.title = t('contact_title')

    }, [])
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            reason: '',
            message: '',
            type: 1
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true)
            msgs.current.clear();
            try {
                let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/home/send-contact-us`, values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': cookies.get('i18next')
                    },
                })
                
                if (data?.status === 200) {
                    showSuccess(data?.message)
                    resetForm()
                    window.scroll(0, 0)
                    setIsLoading(false)

                } else {
                    showError(data?.message)
                    setIsLoading(false)

                }

            } catch (error) { 
                setIsLoading(false)

            }
        }
    });
    const showError = (data) => {
        msgs.current.show({ sticky: true, severity: 'error', summary: `${isLang === "en" ? 'Error : ' : 'خطا تسجيل: '} `, detail: data });
    };

    const showSuccess = (data) => {
        msgs.current.show({ sticky: true, severity: 'success', summary: `${isLang === "en" ? 'Success' : 'تسجيل صحيحه : '} `, detail: data });
    };
    return (
        <div>
            <div className="app__contact_us overflow-x-hidden">
                <Row>
                    <Col xl={7} lg={7} md={12} sm={12} className='h-100'>
                        <div className="contant_form">
                            <header>
                                <h1> {t('nav_agent')}  </h1>
                                <p >  </p>
                            </header>

                            <section className="container-content">
                                <Form onSubmit={formik.handleSubmit}>
                                    <div className={`${isLang === "en" ? '' : 'Messages_Ar'} messages_error   w-100 flex justify-content-center mb-5`} >
                                        <Messages ref={msgs} className='w-100' />
                                    </div>
                                    <Row  >
                                        <Col xl={6} lg={6} md={12} sm={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='label-style'> {t('label_name')} </Form.Label>
                                                <Form.Control
                                                    name="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.name && formik.errors.name}
                                                    className='input-style' type="text"
                                                    placeholder={t('placeholder_name')} />
                                                {formik.touched.name && formik.errors.name && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.name}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12}>

                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                <Form.Label className='label-style'>{t('label_email')}</Form.Label>
                                                <Form.Control
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.email && formik.errors.email}
                                                    className='input-style' type="email" placeholder={t('placeholder_email')} />
                                                {formik.touched.email && formik.errors.email && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.email}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                                <Form.Label className='label-style'>{t('label_phone')}</Form.Label>
                                                <Form.Control
                                                    name="phone"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.phone && formik.errors.phone}
                                                    className='input-style' type="tel" placeholder={t('placeholder_phone')} />
                                                {formik.touched.phone && formik.errors.phone && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.phone}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                                <Form.Label className='label-style'>{t('label_reason')}</Form.Label>
                                                <Form.Control
                                                    name="reason"
                                                    value={formik.values.reason}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.reason && formik.errors.reason}
                                                    className='input-style' type="text" placeholder={t('placeholder_reason')} />
                                                {formik.touched.reason && formik.errors.reason && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.reason}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                                                <Form.Label className='label-style'>{t('label_message')}</Form.Label>
                                                <Form.Control
                                                    name="message"
                                                    value={formik.values.message}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.message && formik.errors.message}
                                                    className='input-style' as="textarea" rows={5}
                                                    placeholder={t('placeholder_message')} />
                                                {formik.touched.message && formik.errors.message && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} marginBottom={'50px'} display="flex" justifyContent='start' alignItems={'center'} gap={'15px'}   >
                                            <Button loading={isLoading} label={t('btn_submit')} />
                                        </Box>
                                        <hr />
                                        <div className="send-message">
                                            <div className="Group d-flex flex-row justify-content-between align-item-center mb-5">
                                                <Component.Message />
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </section>
                        </div>
                    </Col>
                    <Col xl={5} lg={5} md={12} sm={12} className="contact_overlay m-0 p-0  ">
                        <img src={img.Agentbg} alt="" className='w-100 object-fit-contain' />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Agent
