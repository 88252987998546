import axios from 'axios'
import cookies from 'js-cookie'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import img from 'constants/Img'
import { LocalizationContext } from 'context/LangChange'
import './about.scss'
const About = () => { 
  let { t } = useTranslation();
  let {isLang} =useContext(LocalizationContext)
  const [fetchAboutus, setFetchAboutus] = useState([]);
  const fetchAboutusData = async () => {
      const url = `https://holmz.rightclick.com.sa/api/about-us`;
      let { data } = await axios.get(url, {
          headers: {
              'Content-Type': 'application/json',
              'Accept-Language': cookies.get('i18next')
          }
      })
      if (data?.status === 200) {
          setFetchAboutus(data?.data);
      }
  }

  useEffect(() => {
    fetchAboutusData()
  
    return () => {
      fetchAboutusData()

    }
  }, [isLang])
  
  return (
    <div className='app_about'>
      <div className="app__slider bg-black">
        <img src={img.slider} className='w-100 ' alt="" srcset="" />
      </div>
      <>
        <div className='app_about_header'>
          <div className="app_about_title">
            <h1>{fetchAboutus?.title}</h1>
            <p>{fetchAboutus?.description}</p>
          </div>
          <div className="app__about_brand">
            <h1>{fetchAboutus?.brand?.title}</h1>

            <div className="banner">
              <img src={img.AuthBg3} alt="" className='w-100 banner_bg_cover' />
              <div className="overlay__banner">
                <img src={img.AuthBg2} alt="" className='w-100' />
              </div>
              <p>{fetchAboutus?.brand?.description}</p>
            </div>
          </div>
        </div>

        {
          fetchAboutus?.stages?.length > 0 &&
          <div className="app_about_stages mb-8">
            <h1>{t('stages_title')}</h1>

            {
              fetchAboutus?.stages.map((item, index) => (
                <div className="stages mt-7">
                  <Row>
                    <Col xl={5} lg={5} md={12} sm={12} className='flex justify-content-center align-item-center'>
                      <img src={item.image} className='w-75 p-2  ' alt="" srcset="" />
                    </Col>
                    <Col xl={7} lg={7} md={12} sm={12}>
                      <div className="content w-100 h-100 flex  flex-column justify-content-center">
                        <h2 className='px-4'>{item.title}</h2>
                        <p className='px-4'>{item.description}</p>
                      </div>
                    </Col>

                  </Row>
                </div>
              ))
            } 

          </div>
        }
      </>
    </div>
  )
}

export default About